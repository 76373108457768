import React, {useState, useEffect} from "react";
import {FormCardComponent} from "./formCard/FormCardComponent";
import {Rule, RulesList, UnitRuleValuesResponse} from "../unitsResRules.type";
import styles from "./CardListComponent.module.scss";
import {authManager} from "@common/authentication";
import {UnitsResRulesService} from "@common/units-api/";
import {FaCircleNotch} from "react-icons/fa";
import {AdminManagerService} from "../../services/admin-manager.service";
import {useToasts} from "react-toast-notifications";
import moment from "moment";
import {ThemeProvider, Icon, ButtonGroup, Required} from "@vacasa/react-components-lib";
import {CMPLNC_PERMITS_STANDARD_VALUE, GENERAL_RULES_CODE, RULE_CODE, RULES_WITH_EXCLUSIVE_PERMISSIONS} from "../../constants";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import {ViewCardComponent} from "./viewCardComponent/ViewCardComponent";
import {RulesService} from "@common/units-api/lib/src/rules.service";
import {userCanEdit} from "../../utils/utils";
import _ from "lodash";

interface UnitsResRules {}

export const CardListComponent: React.FunctionComponent<UnitsResRules> = (props) => {
    const [unitRuleValues, setUnitRuleValues] = useState([]);
    const [principalListCollection, setPrincipalListCollection] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [unit, setUnit] = useState({unitId: null, display: null, uuid: null});
    const {addToast} = useToasts();

    const adminManager = new AdminManagerService();
    const unitService = new UnitsResRulesService(authManager.getJwt());
    const ruleService = new RulesService(authManager.getJwt());

    const info = authManager.getInfoFromAdmin<any>();
    const canEditRules = userCanEdit(info);
    const canViewRules = info.canView;

    let restrictionErrors = [];

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                const unitInfo = await getUnitInfo();
                if (!_.isEmpty(unitInfo)) {
                    setUnit(unitInfo);
                }

                const unitRuleValuesResponse = await getUnitsRuleValues(unitInfo.uuid);
                if (!_.isEmpty(unitRuleValuesResponse)) {
                    setUnitRuleValues(unitRuleValuesResponse);
                }
                await getRules();
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const getIncludedType = (unitRuleValue, included, type): Promise<Rule> => {
        if (type == "rule") return included.filter((rule) => rule.id === unitRuleValue.attributes.rule_id)[0].attributes;
        if (type == "rule_value") return included.filter((value) => value.id === unitRuleValue.attributes.value_id)[0].attributes;
    };

    const filterRulesByCode = (rulesList, isRuleList: boolean = true) => {
        let completedCanEditRules = canEditRules;
        if (canEditRules.includes(GENERAL_RULES_CODE)) {
            //if user can edit general rules
            //get the rule codes list without duplicated
            const uniqueRules = Array.from(new Set(rulesList.map((rule) => rule.attributes.code ?? rule.attributes.rule?.code))).map((code) => {
                if (isRuleList) return rulesList.find((rule) => rule.attributes.code === code || rule.attributes.rule?.code === code).attributes.code;

                return rulesList.find((rule) => rule.attributes.code === code || rule.attributes.rule?.code === code).attributes.rule.code;
            });

            //add the rules that can be edited
            completedCanEditRules = uniqueRules.filter((code) => canEditRules.includes(code) || !RULES_WITH_EXCLUSIVE_PERMISSIONS.includes(code));
        }

        if (canViewRules && !isRuleList && canEditRules.length == 0) {
            //if user can view rules
            //get the rule codes list without duplicated
            const uniqueRules = Array.from(new Set(rulesList.map((rule) => rule.attributes.code ?? rule.attributes.rule?.code))).map((code) => {
                return rulesList.find((rule) => rule.attributes.code === code || rule.attributes.rule?.code === code).attributes.rule.code;
            });

            //add the rules that can be viewed
            completedCanEditRules = uniqueRules;
        }

        let filteredRules = rulesList.filter((rule) => {
            const code = getCode(isRuleList, rule);
            if (completedCanEditRules.includes(code)) return rule;
        });
        return filteredRules;
    };

    const getCode = (isRuleList, rule) => (isRuleList ? rule.attributes.code : rule.attributes.rule.code);

    const getRules = async () => {
        try {
            const principalList: Array<RulesList> = filterRulesByCode(await ruleService.getRulesList());
            setPrincipalListCollection(principalList);
        } catch (error) {
            console.error("error getRules", error);
            addToast("error with rules", {appearance: "error"});
            return [];
        }
    };

    const getRulesByCode = async (code) => {
        try {
            return await ruleService.getRulesByCode(code);
        } catch (error) {
            console.error("error getValues", error);
            addToast("error with values", {appearance: "error"});
            return [];
        }
    };

    const getUnitInfo = async (): Promise<{unitId: number; display: boolean; uuid: string}> => {
        const unitId = adminManager.getUnitId(),
            display = adminManager.getUnitDisplay(),
            uuid = await unitService.getUuidByLegacyUnitIdV2(unitId);

        return {unitId, display, uuid};
    };

    const getUnitsRuleValues = async (uuid: string) => {
        try {
            if (_.isNil(uuid)) {
                addToast(
                    <div>
                        <h4>This unit hasn't been found.</h4>
                        <p>
                            If this is a brand new unit, you may need to wait a few minutes and try again. If the unit still fails, then contact
                            product or engineering to investigate the error.
                        </p>
                    </div>,
                    {
                        appearance: "info",
                        autoDismiss: false,
                    }
                );
                return [];
            }
            const response = await ruleService.getUnitRuleValues(uuid);
            let unitRuleValuesResponse = response.data;
            const rulesIncluded = response.included.filter((element) => element.type === "rule");
            const valuesIncluded = response.included.filter((element) => element.type === "rule_value");

            if (_.isNil(unitRuleValuesResponse)) return [];
            unitRuleValuesResponse = await unitRuleValuesResponse
                .filter((unitRule) => unitRule.id !== null)
                .map((unitRule) => {
                    return {
                        ...unitRule,
                        statusUI: "lock",
                        attributes: {
                            ...unitRule.attributes,
                            rule: getIncludedType(unitRule, rulesIncluded, "rule"),
                            value: getIncludedType(unitRule, valuesIncluded, "rule_value"),
                        },
                        deleted: false,
                        edited: false,
                        created: false,
                        status: getUnitRuleStatus(unitRule),
                    };
                });

            unitRuleValuesResponse = _.sortBy(unitRuleValuesResponse, ["attributes.rule.name", "attributes.start_date"]);

            unitRuleValuesResponse = await unitRuleValuesResponse.filter((unitRule) => unitRule.id !== null);

            const filteredRules = filterRulesByCode(unitRuleValuesResponse, false);
            return filteredRules.map((unitRule, index) => {
                return {
                    ...unitRule,
                    index: index,
                };
            });
        } catch (error) {
            console.error("error getUnitRules", error);
            addToast("error with unitRules", {appearance: "error"});
            return [];
        }
    };

    const emptyField = (field) => {
        return field === null || field.match(/^ *$/) !== null;
    };

    const setDisabledSaveBtn = () => {
        let disabled = true;
        unitRuleValues.find(function (unitRuleValue, index) {
            if (
                (!emptyField(unitRuleValue.attributes.notes) && (unitRuleValue.edited || unitRuleValue.created)) ||
                (!unitRuleValue.created && unitRuleValue.deleted)
            ) {
                return (disabled = false);
            }
        });
        return disabled;
    };

    let disabledSaveBtn = isLoading === true ? true : unitRuleValues.length === 0 ? true : setDisabledSaveBtn();

    const setUnitRuleValue = (unitRule: UnitRuleValuesResponse, edited?: boolean) => {
        const unitRuleValuesClone = _.cloneDeep(unitRuleValues);
        unitRuleValuesClone[unitRule.index] = {
            ...unitRule,
            edited: edited ?? unitRuleValuesClone[unitRule.index].edited,
            statusUI: unitRule.statusUI,
            status: unitRule.status,
        };
        setUnitRuleValues(unitRuleValuesClone);
        disabledSaveBtn = edited ? edited : disabledSaveBtn;
    };

    const createNewCard = () => {
        addCard({
            valid: false,
            statusUI: "open",
            deleted: false,
        });
    };

    const addCard = (unitRuleValue: any) => {
        unitRuleValue = {
            ...unitRuleValue,
            index: unitRuleValues.length,
            attributes: {
                unit_id: unit.uuid,
                rule: {
                    name: principalListCollection[0].attributes.name,
                    code: principalListCollection[0].attributes.code,
                },
                value: {
                    code: !emptyField(principalListCollection[0].attributes.default_value_code)
                        ? principalListCollection[0].attributes.default_value_code
                        : principalListCollection[0].attributes.values[0].code,
                    description: principalListCollection[0].attributes.description,
                },
                notes: "",
                start_date: moment().format("yyyy-MM-DD"),
                unit_rule_value_attributes: null,
            },
            created: true,
            status: "active",
        };

        const cloneUnitRuleValues = _.cloneDeep(unitRuleValues) as any;
        cloneUnitRuleValues.push(unitRuleValue);
        setUnitRuleValues(cloneUnitRuleValues);
    };

    const pushErrors = (errors: any, attributes: any, requestData?: any) => {
        errors.forEach((e) => {
            const position = e.restrictions[0].position - 1;
            attributes.unit_rule_value_attributes.enrolled[position] = requestData.attributes.unit_rule_value_attributes.enrolled[position];
            if (!attributes.unit_rule_value_attributes.enrolled[position].errors) {
                attributes.unit_rule_value_attributes.enrolled[position].errors = [];
            }
            attributes.unit_rule_value_attributes.enrolled[position].errors.push(e);
        });
        return attributes;
    };

    const addCardWithErrors = (attributes: any, errors: Array<any>, length, requestData: any) => {
        attributes = pushErrors(errors, attributes, requestData);
        const ruleData = principalListCollection.find((rule) => rule.attributes.code === attributes.rule_code);
        const valueData = ruleData.attributes.values.find((value) => value.code === attributes.value_code);
        return {
            type: "unit_rule_value",
            id: attributes.id,
            index: length,
            attributes: {
                unit_id: unit.uuid,
                rule: {
                    code: attributes.rule_code,
                    name: ruleData.attributes.name,
                },
                value: {
                    code: attributes.value_code,
                    description: valueData.description,
                },
                notes: attributes.notes,
                start_date: attributes.start_date,
                end_date: attributes.end_date,
                unit_rule_value_attributes: attributes.unit_rule_value_attributes,
            },
            created: true,
            status: "active",
            valid: false,
            statusUI: "open",
            deleted: false,
            errors,
        };
    };

    const reloadSummary = () => {
        window.parent.postMessage({action: "click", type: "reloadSummary"}, "*");
    };

    const reloadRules = async () => {
        const unitRuleValuesResponse = await getUnitsRuleValues(unit.uuid);
        if (restrictionErrors.length > 0) {
            restrictionErrors.forEach((restriction, index) => {
                if (restriction.data.attributes.id) {
                    //is an old one
                    const index = unitRuleValuesResponse.findIndex((ruleValue) => {
                        return ruleValue.id === restriction.data.attributes.id;
                    });
                    unitRuleValuesResponse[index]["valid"] = false;
                    unitRuleValuesResponse[index]["statusUI"] = "open";
                    unitRuleValuesResponse[index]["deleted"] = false;
                    unitRuleValuesResponse[index].attributes = pushErrors(
                        restriction.errors || [],
                        unitRuleValuesResponse[index].attributes,
                        restriction.data
                    );
                } else {
                    //is a new one
                    unitRuleValuesResponse.push(
                        addCardWithErrors(restriction.data.attributes, restriction.errors || [], unitRuleValuesResponse.length, restriction.data)
                    );
                }
            });
        }
        setUnitRuleValues(unitRuleValuesResponse);
    };

    const CompliancePermitRuleValidation = (item) => {
        if (
            item.attributes.rule.code === RULE_CODE.CMPLNC_PERMITS &&
            item.attributes.unit_rule_value_attributes?.hasOwnProperty("override_requirement")
        )
            if (
                item.attributes.unit_rule_value_attributes.override_requirement === false ||
                item.attributes.value.code === CMPLNC_PERMITS_STANDARD_VALUE
            ) {
                item.attributes.unit_rule_value_attributes.override_effective_date = null;
                item.attributes.unit_rule_value_attributes.override_end_date = null;
                item.attributes.unit_rule_value_attributes.override_notes = null;
                item.attributes.unit_rule_value_attributes.override_requirement = false;
            }
        return item;
    };
    const saveRules = async () => {
        const info = authManager.getInfoFromAdmin<{user: string; unitId: number}>();

        setIsLoading(true);

        if (!unit.uuid) {
            addToast("error saving rules, uuid was not found.", {appearance: "error"});
            setIsLoading(false);
            return;
        }

        const created = [];
        const updated = [];
        const deleted = [];

        for (let item of unitRuleValues as Array<UnitRuleValuesResponse>) {
            item = CompliancePermitRuleValidation(item);
            if (item.attributes.rule.code == RULE_CODE.UNIT_STAY_RESTRICTIONS) {
                if (item.attributes.unit_rule_value_attributes) {
                    item.attributes.unit_rule_value_attributes.enrolled = item.attributes.unit_rule_value_attributes?.enrolled.map((r) => {
                        return {
                            restriction_type: r.restriction_type,
                            restriction_number: r.restriction_number,
                            restriction_duration: r.restriction_duration,
                            restriction_duration_type: r.restriction_duration_type,
                            restriction_effective_type: r.restriction_effective_type,
                            restriction_effective_start_date: r.restriction_effective_start_date,
                            restriction_effective_end_date: r.restriction_effective_end_date,
                        };
                    });
                }
            }
            if (item.attributes.rule.code === RULE_CODE.SEASONAL_RATES) {
                if (item.attributes.unit_rule_value_attributes) {
                    item.attributes.unit_rule_value_attributes.enrolled = item.attributes.unit_rule_value_attributes?.enrolled?.map((r) => {
                        delete r.isNew;
                        delete r.statusUI;
                        delete r.errors;
                        return r;
                    });
                }
            }

            if (item.deleted && item.id) {
                deleted.push({id: item.id, done_by: info.user});
            } else if (item.edited && item.id) {
                const itemToUpdate = {
                    type: item.type,
                    id: item.id,
                    attributes: {
                        id: item.id,
                        rule_code: item.attributes.rule.code,
                        value_code: item.attributes.value.code,
                        notes: item.attributes.notes !== "" ? item.attributes.notes : null,
                        unit_rule_value_attributes: item.attributes.unit_rule_value_attributes,
                        start_date: item.attributes.start_date,
                        end_date: item.attributes.end_date !== "" ? item.attributes.end_date : null,
                        done_by: info.user,
                    },
                };
                updated.push(itemToUpdate);
            } else if (item.created && !item.deleted) {
                const itemToCreate = {
                    type: "unit_rule_value",
                    attributes: {
                        unit_id: item.attributes.unit_id,
                        rule_code: item.attributes.rule.code,
                        value_code: item.attributes.value.code,
                        notes: item.attributes.notes !== "" ? item.attributes.notes : null,
                        unit_rule_value_attributes: item.attributes.unit_rule_value_attributes,
                        start_date: item.attributes.start_date,
                        end_date: item.attributes.end_date ?? null,
                        done_by: info.user,
                    },
                };
                created.push(itemToCreate);
            }
        }

        await saveUnitRuleValues(updated, created, deleted);
    };

    const isTypeOf = (obj: any, type: string): boolean => {
        return typeof obj === type;
    };

    const customizeErrorsMessage = (errorMessage: string): string => {
        if (errorMessage.includes('rate_effective_start_date, must be string')) {
            return errorMessage.replace('rate_effective_start_date, must be string', 'rate_start_date should not be empty');
        }

        if (errorMessage.includes('rate_effective_end_date, Rate End date should be valid.')) {
            return errorMessage.replace('rate_effective_end_date, Rate End date should be valid.', 'rate_end_date, Rate End date should be valid.');
        }

        if (errorMessage.includes('rate_effective_start_date, Rate Effective date should be valid.')) {
            return errorMessage.replace('rate_effective_start_date, Rate Effective date should be valid.', 'rate_start_date, Rate Start date should be valid.');
        }

        return errorMessage;
    }

    const showErrorMessage = (result: any) => {
        let data = JSON.parse(result.reason.config.data);
        const e = result.reason.response.data[0];
        if (isTypeOf(e.detail.error, "object") && !_.isNil(e.detail.error)) {
            for (let msg in e.detail.error) {
                if (e.detail.error[msg]["constraints"]) {
                    addToast(`Error on field ${msg}: ${e.detail.error[msg]["constraints"][0]}`, {
                        appearance: "error",
                        autoDismissTimeout: 10000,
                    });
                } else if (e.detail.error[msg]["message"]) {
                    e.detail.error[msg]["message"] = customizeErrorsMessage(e.detail.error[msg]["message"]);
                    addToast(`${e.detail.error[msg]["message"]}`, {
                        appearance: "error",
                        autoDismissTimeout: 10000,
                    });
                }
            }

            const ruleCode = data.data.attributes.rule_code;
            const ruleCodeErrors = [RULE_CODE.UNIT_STAY_RESTRICTIONS, RULE_CODE.SEASONAL_RATES];
            if (ruleCodeErrors.includes(ruleCode)) {
                data.errors = e.detail.error;
                restrictionErrors.push(data);
                addToast("At least one rule has error", {appearance: "error"});
            }
        } else if (isTypeOf(e.detail.error, "string")) {
            const detailError = e.detail.error;

            // Seasonal Rates error messages
            const seasonalInvalidJsonError = "Seasonal Rates attributes is not a valid JSON object.";
            const seasonalEmptyError = "Seasonal Rates attributes is empty or enrolled field is not an array or is empty.";

            // Unit Stay Rule error messages
            const unitStayInvalidJsonError = "Seasonal Rates attributes is not a valid JSON object.";
            const unitStayIEmptyError = "Seasonal Rates attributes is empty or enrolled field is not an array or is empty.";

            if (detailError === seasonalInvalidJsonError || detailError === seasonalEmptyError) {
                addToast("Seasonal Rates Rule must have records", {appearance: "error"});
            } else if (detailError === unitStayInvalidJsonError || detailError === unitStayIEmptyError) {
                addToast("Unit Stay Rule must have restrictions", {appearance: "error"});
            } else {
                addToast(detailError, {appearance: "error"});
            }
        } else {
            let errorMsg = e.detail.error;
            const ruleNotValidError = "Rule attributes is not valid: face_to_face_checkin_required, must be equal to one of the allowed values.";
            if (isTypeOf(e.detail.error, "string") && errorMsg === ruleNotValidError) {
                errorMsg = `Face to Face Check-in Required must be selected`;
            }

            errorMsg = _.isNil(errorMsg) ? "An unexpected error has occurred." : errorMsg;
            addToast(`${errorMsg}`, {appearance: "error", autoDismissTimeout: 10000});
        }
    };

    const saveUnitRuleValues = async (updated, created, deleted) => {
        const promises = [];
        deleted.forEach((x) => {
            promises.push(ruleService.deleteUnitRule(x));
        });
        updated.forEach((x) => {
            promises.push(ruleService.updateUnitRule(x.id, x));
        });
        created.forEach((x) => {
            promises.push(ruleService.createUnitRule(x));
        });

        Promise.allSettled(promises)
            .then((results) => {
                restrictionErrors = [];
                if (results.some((p) => p.status == "rejected")) {
                    results.forEach((result) => {
                        if (result.status == "rejected") {
                            showErrorMessage(result);
                        }
                    });
                } else {
                    addToast("Saved rules", {appearance: "success"});
                }
            })
            .finally(() => {
                setIsLoading(false);
                reloadRules();
                reloadSummary();
            });
    };

    const totalInactiveRules = unitRuleValues.filter(
        (unitRuleValue) => unitRuleValue.status === "inactive" && !unitRuleValue.deleted && unitRuleValue.id !== null
    );
    const totalFutureRules = unitRuleValues.filter(
        (unitRuleValue) => unitRuleValue.status === "future" && !unitRuleValue.deleted && unitRuleValue.id !== null
    );
    const totalActiveRules = unitRuleValues.filter(
        (unitRuleValue) => unitRuleValue.status === "active" && !unitRuleValue.deleted && unitRuleValue.id !== null
    );

    const getUnitRuleStatus = (unitRuleValue) => {
        const today = moment().format("YYYY-MM-DD");
        if (unitRuleValue.attributes.end_date < today) return "inactive";
        if (unitRuleValue.attributes.start_date > today) return "future";
        return "active";
    };

    const left = {
        variant: "info" as any,
        onClick: createNewCard,
        children: (
            <React.Fragment>
                <Icon.Plus style={{fill: "red"}} />
                Add New
            </React.Fragment>
        ),
        disabled: isLoading,
    };

    const right = {
        variant: "secondary" as any,
        onClick: saveRules,
        children: "Save",
        disabled: disabledSaveBtn,
    };

    const editPermit = canEditRules.length > 0;

    const showEditOrView = (index, unitRuleValue) => {
        return editPermit ? (
            <FormCardComponent
                key={index}
                setFormItem={setUnitRuleValue}
                unitRuleValue={unitRuleValue}
                principalListCollection={principalListCollection}
                getValues={getRulesByCode}
                currency={info["currency"]}
            />
        ) : (
            <ViewCardComponent key={index} setFormItem={setUnitRuleValue} unitRuleValue={unitRuleValue} />
        );
    };
    return (
        <div className="container">
            <div className={styles.titleShelf}>
                <h4 className={"left"}>Unit-Specific Reservation Rules</h4>
                {isLoading ? (
                    <FaCircleNotch className="fa-spin" />
                ) : (
                    <ThemeProvider theme={_default}>
                        <Required
                            text={`${unitRuleValues.filter((obj) => obj.deleted === false).length} Rules Configured`}
                            type={"info"}
                            size={"small"}
                            customClass={styles.counterRules}
                        ></Required>
                    </ThemeProvider>
                )}
            </div>
            <div className={`content-items ${styles.contentRules}`}>
                {totalActiveRules.length > 0 ? (
                    <div className="items">
                        <h6 className={`${styles.subtitle} center`}>Active Rules</h6>
                        {totalActiveRules.map((unitRule: UnitRuleValuesResponse, index) => {
                            return showEditOrView(index, unitRule);
                        })}
                        <hr></hr>
                    </div>
                ) : (
                    <div></div>
                )}
                {totalFutureRules.length > 0 ? (
                    <div className="items">
                        <h6 className={`${styles.subtitle} center`}>Future Rules</h6>
                        {totalFutureRules.map((unitRule: UnitRuleValuesResponse, index) => {
                            return showEditOrView(index, unitRule);
                        })}
                        <hr></hr>
                    </div>
                ) : (
                    <React.Fragment />
                )}
                {totalInactiveRules.length > 0 ? (
                    <div className="items">
                        <h6 className={`${styles.subtitle} center`}>Inactive Rules</h6>
                        {totalInactiveRules.map((unitRule: UnitRuleValuesResponse, index) => {
                            return showEditOrView(index, unitRule);
                        })}
                    </div>
                ) : (
                    <React.Fragment />
                )}
            </div>

            {editPermit ? (
                <div className="content-footer float-right">
                    <ThemeProvider theme={_default}>
                        <ButtonGroup left={left} right={right}></ButtonGroup>
                    </ThemeProvider>
                </div>
            ) : (
                <React.Fragment />
            )}
        </div>
    );
};
